import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f30673e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carouselDetail" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.detail.title), 1),
      _createElementVNode("article", {
        innerHTML: _ctx.detail.content
      }, null, 8, _hoisted_4)
    ])
  ]))
}