
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { getDetail } from '@/apis/carouselDetail'
import { useRouter, useRoute } from 'vue-router'
import { CarouselDetailItem } from '@/model/carouselDetail'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const dataMap = reactive({
      detail: {} as CarouselDetailItem
    })
    const methods = reactive({
      async getList() {
        const id = route.query.id ?? ''
        if (id === '') return
        const res = await getDetail(`${id}`)
        dataMap.detail = res?.data ?? {} as CarouselDetailItem
      },
      onBack() {
        router.push('/')
      }
    })

    onMounted(() => {
      methods.getList()
    })

    return {
      ...toRefs(dataMap),
      ...toRefs(methods)
    }
  }
})
